body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#app-menu-selected {
  background: #c92279;
  color: #ffffff;
  padding-left: 12px;
  display: flex;
  gap: 8px;
}

.white-text .dx-button-content, .white-text .dx-icon {
  color: #fff !important;
}

.dx-datagrid-header-panel {
  padding: 8px;
}

.dx-datagrid-header-panel .dx-toolbar {
  margin: 0;
}

.dx-treeview-item {
  cursor: pointer;
  padding: 5px 6px;
  min-height: 32px;
  border-radius: 8px 16px 16px 8px;
  margin-top: 4px;
  transition: padding .2s;
}